import React, {useEffect, useState} from "react";
import { bindActionCreators } from "redux";
import {connect, useDispatch} from "react-redux";
import axiosInstance from "../../../utils/apiCalls";
import {TOKEN} from "../../../utils/consts";
import {updateToken} from "../../../storage/actions/UserActions";
import {useNavigate} from "react-router-dom";

function ProfileScreen({ user }) {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    let navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    }, []);

    const logout = () => {
        localStorage.removeItem(TOKEN);
        dispatch(updateToken({id: 0, token: null}));

        navigate('/login')
    }

    const handleChangePassword = (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setErrors({ confirmPassword: "Пароли не совпадают" });
            return;
        }

        // api call to change password
        axiosInstance.post("/users/change-password", {
            currentPassword,
            newPassword,
            confirmPassword,
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }).then((response) => {
            if (response.data.success) {
                logout();
            }
            setErrors({});
        }).catch((error) => {
            if (error.response && error.response.data && error.response.data.errors) {
                const serverErrors = error.response.data.errors.reduce((acc, err) => {
                    acc[err.name] = err.message;
                    return acc;
                }, {});
                setErrors(serverErrors);
            } else {
                console.error(error);
            }
        });

        console.log("Password change submitted", { currentPassword, newPassword });
    };

    return (
        <div className="bg-light p-5 rounded">
            <h1>Изменить пароль</h1>
            <form onSubmit={handleChangePassword}>
                <div className="mb-3">
                    <label htmlFor="currentPassword" className="form-label">Текущий пароль</label>
                    <input
                        type="password"
                        className={`form-control ${errors.currentPassword ? 'is-invalid' : ''}`}
                        id="currentPassword"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                    />
                    {errors.currentPassword && <div className="invalid-feedback">{errors.currentPassword}</div>}
                </div>
                <div className="mb-3">
                    <label htmlFor="newPassword" className="form-label">Новый пароль</label>
                    <input
                        type="password"
                        className={`form-control ${errors.newPassword ? 'is-invalid' : ''}`}
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                    {errors.newPassword && <div className="invalid-feedback">{errors.newPassword}</div>}
                </div>
                <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label">Подтвердите новый пароль</label>
                    <input
                        type="password"
                        className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
                </div>
                <button type="submit" className="btn btn-primary">Изменить пароль</button>
            </form>
        </div>
    );
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);
