import React, {useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import {API_URL} from "../../utils/consts";
import axios from "axios";
import axiosInstance from "../../utils/apiCalls";
import fileDownload from "js-file-download";
import {findReplaceString} from "../../utils/helpers";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export function StyledDropzone({text, projectName, blockType, deal, token, onSuccessUpload, readonly, clearErrors}) {
    const {
        getRootProps,
        acceptedFiles,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        // we need accept image and pdf doc docx xlsx xls
        accept: {
            'image/*': [],
            'application/pdf': [],
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
        },
        onDrop: (files) => _onDrop(files)
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const _onDrop = (files) => {
        files.map(file => {
            let formData = new FormData();
            formData.append("uploadedFile", file);
            axiosInstance.post(API_URL + '/file/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    modelName: 'FileDeal',
                    ownerId: deal.id,
                    blockType,
                    projectName
                }
            })
                .then(response => response.data)
                .then(response => onSuccessUpload())
                .catch(errorResponse => {
                    console.log(errorResponse)
                }).finally(() => clearErrors('Deal'))

        })
    }

    const _downloadFile = (url, name) => {
        axiosInstance.get(API_URL + url, {
            responseType: 'blob',
        }).then(response => {
            fileDownload(response.data, name)
        })
    }

    const _deleteFile = (id) => {
        axiosInstance.delete(API_URL + '/file/delete', {
            params: {
                modelName: 'common\\models\\file\\FileDeal',
                id
            }
        })
            .then(response => response.data)
            .then(response => {
                if (response.success) {
                    onSuccessUpload()
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div className="container">
            {!readonly && (
                <div {...getRootProps({style})}>
                    <input {...getInputProps()} />
                    <p>{text}</p>
                </div>
            )}
            <table className="table table-hover">
                <thead>
                <tr>
                    <th>Название</th>
                    <th>Размер</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {deal.files.map((file) => {
                    return (
                        <tr key={file.id}>
                            <td>{file.name + '.' + file.ext}</td>
                            <td>{file.size}</td>
                            <td>
                                <a className={'direct-link'}
                                   onClick={() => _downloadFile(file.url, file.name + '.' + file.ext)}>Скачать</a>
                                &nbsp;&nbsp;&nbsp;
                                <a className={'direct-link'}
                                   onClick={() => _deleteFile(file.id)}>Удалить</a>
                            </td>
                            <td></td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
}

