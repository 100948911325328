import {fetchExportList, handleExportJob} from "../storage/actions/ExportActions"; // Import other actions as needed
import {isDoneGenerating} from "../storage/actions/DealActions";

export function handleWebSocketMessage(dispatch, data) {
    const { action, message } = data;

    switch (action) {
        case "EXPORT_JOB":
            fetchExportList(message.unique_type)(dispatch);
            break;
        case "SUCCESS_GENERATED_SIGN_DOCUMENTS":
            dispatch(isDoneGenerating({ isNeedRefresh: true, ...data.data }));
            break;
        default:
            console.warn("Unknown WebSocket action:", action);
    }
}
