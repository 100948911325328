import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {API_URL} from "../../utils/consts";
import ReactPaginate from "react-paginate";
import {NavLink, useSearchParams} from "react-router-dom";
import SearchComponent from "./SearchComponent";
import {currencyFormat} from "../../utils/currency";
import ExportExcel from "../../components/exportExcel/ExportExcel";
import {getBadgeType, userCan} from "../../utils/helpers";
import axiosInstance from "../../utils/apiCalls";
import queryString from "query-string";

function DealScreen({user}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [deals, setDeals] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalDeals, setTotalDeals] = useState(0);
    const [totalSum, setTotalSum] = useState({});
    const [query, setQuery] = useSearchParams({page: 1});
    const [filterParams, setFilterParams] = useState({});

    useEffect(() => {
        if (!isLoading) {
            const queryStringParams = queryString.parse(window.location.search);
            if (queryStringParams.DealSearch) {
                setFilterParams({DealSearch: JSON.parse(queryStringParams.DealSearch)});
                fetchDeals({DealSearch: JSON.parse(queryStringParams.DealSearch)});
            } else {
                fetchDeals({});
            }
        }
    }, [filterParams]);

    const fetchDeals = (params = {}) => {
        setIsLoading(true);
        const url = API_URL + '/deals/index';

        const page = parseInt(query.get('page')) || 1;
        const requestParams = {page, ...filterParams, ...params};

        axiosInstance
            .get(url, {params: requestParams})
            .then(response => {
                setTotalPages(response.headers.get('x-pagination-page-count'));
                setTotalDeals(response.headers.get('x-pagination-total-count'));
                return response.data;
            })
            .then(responseJson => {
                setDeals(responseJson.data);
                fetchDealTotalSum({params: requestParams})
            }).catch(errorResponse => {
            console.error("Error fetching deals:", errorResponse);
        }).finally(() => {
            setIsLoaded(true);
            setIsLoading(false);

            const urlParams = {
                page: requestParams.page,
                DealSearch: JSON.stringify(requestParams.DealSearch || {})
            };

            setQuery(urlParams);
        });
    };

    const fetchDealTotalSum = (params = {}) => {
        const url = API_URL + '/deals/get-deal-sum';
        axiosInstance.get(url, params)
            .then(response => {
                return response.data;
            })
            .then(responseJson => {
                setTotalSum(responseJson.data);
            }).catch(errorResponse => {
            console.error("Error fetching deals:", errorResponse);
        }).finally(() => {
            setIsLoaded(true);
        });
    }

    const handleFilterChange = (newFilters) => {
        setFilterParams(newFilters);
        fetchDeals(newFilters);
    };

    return (
        <>
            <div className="bg-light p-lg-5 mb-4 rounded table-responsive ">
                <div className={"row"}>
                    <div className={"col-12 col-md-6 col-lg-4"}>
                        <h3>Количество продаж:</h3>
                        <h4>{totalDeals}<small>(шт.)</small></h4>
                    </div>
                    {totalSum.totalSumFormatted && (
                        <div className={"col-12 col-md-6 col-lg-4"}>
                            <h3>Итог продаж:</h3>
                            <h4>{totalSum.totalSumFormatted}</h4>
                        </div>
                    )}
                    {totalSum.totalSumTotalAmountFormatted && (
                        <div className={"col-12 col-md-6 col-lg-4"}>
                            <h3>Итого поступлений:</h3>
                            <h4>{totalSum.totalSumTotalAmountFormatted}</h4>
                        </div>
                    )}
                </div>
            </div>
            <SearchComponent fetchDeals={handleFilterChange} filterParams={filterParams} isLoading={isLoading}
                             user={user}/>

            <div className="bg-light p-lg-5 rounded table-responsive">
                <h2 className="page-title float-start">Список сделок</h2>
                <div className={"float-end d-inline-flex"}>
                    <NavLink to={"/deal/create"} className="btn btn-primary">
                        Создать сделку
                    </NavLink>
                    <ExportExcel token={user.token} totalDeals={totalDeals} exportType={'dealExport'}
                                 filterData={filterParams}/>
                </div>

                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Номер сертификата</th>
                        <th scope="col">Дата сделки</th>
                        <th scope="col">Статус</th>
                        <th scope="col">Проект</th>
                        {userCan(user.roles, ['admin', 'backoffice', 'supervisor']) && (
                            <th scope="col">Структурное подразделение</th>
                        )}
                        {userCan(user.roles, ['admin', 'backoffice', 'supervisor']) && (
                            <th scope="col">Банк</th>
                        )}
                        <th scope="col">Продукт</th>
                        <th scope="col">Тариф</th>
                        <th scope="col">Стоимость</th>
                        <th scope="col">Клиент ФИО</th>
                        <th scope="col">Телефон</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {deals.map(deal => (
                        <tr key={deal.id} className={deal.is_test ? 'table-warning' : ''}>
                            <td>{deal.contract_code}</td>
                            <td>{deal.issued_date}</td>
                            <td><span className={getBadgeType(deal.statusOptions.label)}>{deal.status}</span></td>
                            <td>{deal.projectName}</td>
                            {userCan(user.roles, ['admin', 'backoffice', 'supervisor']) && (
                                <td>{deal.projectOrganizationTitle}</td>
                            )}
                            {userCan(user.roles, ['admin', 'backoffice', 'supervisor']) && (
                                <td>
                                    {deal?.bank?.bank_credit_bic && (
                                        <>
                                            {deal.bank.bank_credit_name}
                                            <br/>
                                            БИК {deal.bank.bank_credit_bic}
                                        </>
                                    )}
                                </td>
                            )}

                            <td>{deal.productName}</td>
                            <td>{deal.servicesPackages}</td>
                            <td>{currencyFormat(deal.price)}</td>
                            <td>{deal.client_fio}</td>
                            <td><NavLink to={"tel:+" + deal.client_phone}/>{deal.client_phone}</td>
                            <td><NavLink to={'/deal/' + deal.id + '/update'}
                                         className={"btn btn-sm btn-outline-secondary"}>Подробнее</NavLink></td>
                        </tr>
                    ))}
                    </tbody>
                    {isLoading && (
                        <tfoot>
                        <tr>
                            <td colSpan={11} className={'text-center'}>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Загрузка...</span>
                                </div>
                            </td>
                        </tr>
                        </tfoot>
                    )}
                </table>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="Следующая →"
                    pageCount={totalPages}
                    previousLabel="← Предыдущая"
                    onPageChange={({selected}) => {
                        const newPage = selected + 1;
                        setQuery({page: newPage, DealSearch: JSON.stringify(filterParams.DealSearch || {})});
                        fetchDeals({page: newPage, ...filterParams}); // Учитываем фильтры при смене страницы
                    }}
                    pageRangeDisplayed={8}
                    className={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'page-item active'}
                    renderOnZeroPageCount={null}
                />
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DealScreen);
