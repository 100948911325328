import React, {memo, useState} from "react";
import axios from "axios";

const API_TOKEN = '8a45cda739ec8bc692e84e0469333c419700a55f';

const InputDaDataWrapper = ({
                                id,
                                label,
                                errors,
                                register,
                                setValue = (e) => {
                                },
                                onChange = (e) => {
                                },
                                isRequired = true,
                                readOnly = false,
                                disabled = false,
                                isVisible = false,
                                widget = 'bank',
                            }) => {

    const [suggestions, setSuggestions] = useState([]);
    const [timeoutId, setTimeoutId] = useState(null);

    if (!isVisible) {
        return null;
    }

    const handleChange = (event) => {
        const query = event.target.value;

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        const newTimeoutId = setTimeout(async () => {
            if (query.length > 2) {
                try {
                    const response = await axios.post(
                        `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/${widget}`,
                        {query},
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Token ${API_TOKEN}`,
                            },
                        }
                    );
                    setSuggestions(response.data.suggestions);
                } catch (error) {
                    console.error('Ошибка при получении данных DaData', error);
                }
            } else {
                setSuggestions([]); // Очищаем подсказки, если текст короче 3 символов
            }
        }, 500);

        setTimeoutId(newTimeoutId);
    };

    const handleSuggestionClick = (suggestion) => {
        setValue(`Deal.${id}`, suggestion.value);
        if (widget === 'bank') {
            setValue('Deal.bank_credit_bic', suggestion.data.bic);
            setValue('Deal.bank_credit_inn', suggestion.data.inn);
        }

        setSuggestions([]);
    };

    return (
        <div className="mb-3 position-relative" style={{width: '100%'}}>
            <label htmlFor={id} className="form-label">{label}</label>
            <input
                id={id}
                readOnly={readOnly}
                disabled={disabled}
                className={"form-control " + (errors ? "is-invalid" : '')}
                {...register(`Deal.${id}`, {
                    required: isRequired ? `Необходимо заполнить «${label}».` : false
                })}
                onChange={handleChange}
            />
            {suggestions.length > 0 && (
                <ul className="suggestions-list">
                    {suggestions.map((suggestion) => (
                        <li
                            key={suggestion.value}
                            className="suggestion-item"
                            onClick={() => handleSuggestionClick(suggestion)}
                        >
                            <div className="suggestion-name">{suggestion.value}</div>
                            <div className="suggestion-details">
                                <span>БИК: {suggestion.data.bic}</span> | <span>ИНН: {suggestion.data.inn}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
            {errors && (
                <div className="invalid-feedback">
                    {errors.message}
                </div>
            )}
        </div>
    );
}

export default memo(InputDaDataWrapper);
