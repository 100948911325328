import React, {memo, useState} from "react";
import axiosInstance from "../../utils/apiCalls";

const DealVerification = ({deal, stamp}) => {
    const [signImagePath, setSignImagePath] = useState(null);
    const [isVerified, setIsVerified] = useState(deal.dealInfo.sign_verification_verified);
    const [clientPhone, setClientPhone] = useState(deal.dealInfo.client_phone);
    const [dealId, setDealId] = useState(deal.id);

    const [smsSent, setSmsSent] = useState(false);
    const [smsCode, setSmsCode] = useState("");
    const [message, setMessage] = useState({text: "", type: ""});
    const [isConfirmed, setIsConfirmed] = useState(isVerified);

    const handleSendSms = async () => {
        setMessage({text: "", type: ""});
        try {
            const response = await axiosInstance.get(`/deal/verification/send-sms`, {params: {dealId}});
            if (response.data.success) {
                setSmsSent(true);
                setMessage({text: "SMS успешно отправлено!", type: "success"});
            } else {
                setMessage({text: response.data.message, type: "danger"});
            }
        } catch (error) {
            setMessage({text: "Ошибка при отправке SMS", type: "danger"});
        }
    };

    const handleConfirmCode = async () => {
        if (!smsCode) {
            setMessage({text: "Введите код подтверждения", type: "danger"});
            return;
        }

        setMessage({text: "", type: ""});

        try {
            const response = await axiosInstance.post(`/deal/verification/verify-code`, {dealId, code: smsCode}, {
                params: {dealId},
                headers: {"Content-Type": "application/x-www-form-urlencoded"},
            });
            if (response.data.success) {
                setIsConfirmed(true);
                setMessage({text: "Код успешно подтверждён!", type: "success"});
            } else {
                setMessage({text: "Неверный код. Попробуйте еще раз.", type: "danger"});
            }
        } catch (error) {
            setMessage({text: "Ошибка при проверке кода", type: "danger"});
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <p><strong>Номер телефона:</strong> {clientPhone}</p>

                {isConfirmed ? (
                    <>
                        <div className="alert alert-success">Сделка подтверждена с цифровой подписью.</div>
                        <div className="p-3" style={{maxWidth: "600px"}}>
                            <img src={`data:image/png;base64,${stamp}`} alt="Подпись"
                                 style={{maxWidth: "100%"}}/>
                        </div>
                    </>
                ) : (
                    <>
                        {!signImagePath && (
                            <>
                    <a className="btn btn-primary" onClick={handleSendSms}>
                                    Отправить SMS
                                </a>

                                {smsSent && (
                                    <div style={{marginTop: "20px"}}>
                                        <input
                                            type="text"
                                            value={smsCode}
                                            onChange={(e) => setSmsCode(e.target.value)}
                                            className="form-control"
                                            placeholder="Введите код подтверждения"
                                        />
                                        <a className="btn btn-success mt-2" onClick={handleConfirmCode}>
                                            Подтвердить
                                        </a>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}

                {message.text && (
                    <div className={`mt-3 text-${message.type}`}>
                        {message.text}
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(DealVerification);
