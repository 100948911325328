import {useForm} from "react-hook-form";
import React, {useEffect, useRef, useState} from "react";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import {API_URL} from "../../utils/consts";
import axiosInstance from "../../utils/apiCalls";
import {formatDateRange, userCan} from "../../utils/helpers";

const SearchComponent = ({fetchDeals, isLoading, user}) => {
    const [project, setProject] = useState({});
    const [projects, setProjects] = useState([]);

    const [organizations, setOrganizations] = useState([]);
    const [organization, setOrganization] = useState({});

    const [product, setProduct] = useState({});
    const [products, setProducts] = useState([]);

    const [price, setPrice] = useState({});
    const [prices, setPrices] = useState([]);

    const [status, setStatus] = useState(null);
    const [statuses, setStatuses] = useState([]);

    const [paymentStatus, setPaymentStatus] = useState(null);
    const [paymentStatuses, setPaymentStatuses] = useState([]);

    const [issuedDateRange, setIssuedDateRange] = useState([null, null]);
    const [issuedStartDate, issuedEndDate] = issuedDateRange;

    const [isTest, setIsTest] = useState({});
    const [testsOptions, setTestsOptions] = useState([
        {
            value: null,
            label: 'Выбрать'
        },
        {
            value: 1,
            label: 'Да'
        },
        {
            value: 0,
            label: 'Нет'
        },
    ]);

    useEffect(() => {
        fetchProjects();
        fetchStatuses();
    }, []);

    useEffect(() => {
        if (projects.length === 1) setProject(projects[0]);
    }, [projects]);

    useEffect(() => {
        if (products.length === 1) setProduct(products[0]);
    }, [products]);


    useEffect(() => {
        if (project.value) {
            fetchProducts(project.value);
            fetchOrganizations(project.value)
        }
    }, [project.value]);

    useEffect(() => {
        if (project.value) {
            fetchPrices(project.value, product.value);
        }
    }, [project.value, product.value]);

    const fetchProjects = () => {
        axiosInstance.get(API_URL + '/dictionary/projects', {})
            .then(response => response.data)
            .then(responseData => {
                setProjects(responseData.data.map(project => ({
                    value: project.id.toString(),
                    label: project.title
                })))
            }).catch(errorResponse => {

        }).finally(() => {
        })
    }

    const fetchOrganizations = (projectId) => {
        axiosInstance.get(API_URL + '/dictionary/organizations', {params: {projectId: projectId}})
            .then(response => response.data)
            .then(responseData => {
                console.log(responseData)
                setOrganizations(responseData.data.map(organization => ({
                    value: organization.id.toString(),
                    label: organization.name
                })))
            }).catch(errorResponse => {

        }).finally(() => {
        })
    }

    const fetchStatuses = () => {
        axiosInstance.get(API_URL + '/dictionary/statuses', {
            headers: {Authorization: `Bearer ${user.token}`},
        })
            .then(response => response.data)
            .then(responseData => {
                setStatuses(responseData.data.defaultStatuses.map(status => ({
                    value: status.name,
                    label: status.name
                })))
                setPaymentStatuses(Object.entries(responseData.data.paymentStatuses).map(([id, status]) => ({
                    value: id,
                    label: status
                })))
            }).catch(errorResponse => {

        })
    }

    const fetchProducts = (projectID) => {
        axiosInstance.get(API_URL + '/dictionary/products', {
            params: {
                projectId: projectID
            }
        })
            .then(response => response.data)
            .then(responseData => {
                setProducts(responseData.data.map(product => ({
                    value: product.id.toString(),
                    label: product.title
                })))
            }).catch(errorResponse => {

        }).finally(() => {
        })
    }

    const fetchPrices = (projectId, productId = null) => {
        axiosInstance.get(API_URL + '/dictionary/prices', {
            params: {
                projectId,
                productId
            }
        })
            .then(response => response.data)
            .then(responseData => {
                setPrices(responseData.data.map(price => ({
                    value: price.id.toString(),
                    label: price.title
                })))
            }).catch(errorResponse => {

        }).finally(() => {
        })
    }

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        resetField,
        formState: {errors},
    } = useForm({
        defaultValues: {
            DealSearch: {
                contract_code: "",
                product_id: "",
                project_organization_id: "",
                product_product_id: "",
                product_price_id: "",
            },
        },
    })

    const _resetForm = () => {
        window.location.href = '/deals';
    }

    return (
        <div className="bg-light p-lg-5 mb-4 rounded  ">
            <form onSubmit={handleSubmit(fetchDeals)} method={'get'}>
                <div className="row">
                    {projects.length > 1 && (<div className="mb-3 col-md-3">
                        <label htmlFor="projectId" className="form-label">Проект</label>
                        <Select
                            defaultValue={project}
                            value={project || null}
                            placeholder={'Выбрать проект'}
                            onChange={(newValue) => {
                                setProduct({})
                                setPrice({})
                                setProject(newValue)
                                setValue('DealSearch.project_id', newValue.value)
                            }}
                            options={projects}/>
                    </div>)}
                    {products.length > 1 && (<div className="mb-3 col-md-3">
                        <label htmlFor="productId" className="form-label">Продукт</label>
                        <Select
                            defaultValue={product}
                            value={product || null}
                            placeholder={'Выбрать продукт'}
                            onChange={(newValue) => {
                                setProduct(newValue)
                                setPrice({});
                                setValue('DealSearch.project_product_id', newValue.value)
                            }}
                            options={products}/>
                    </div>)}
                    <div className="mb-3 col-md-3">
                        <label htmlFor="productId" className="form-label">Тариф</label>
                        <Select
                            defaultValue={price}
                            value={price || null}
                            placeholder={'Выбрать тариф'}
                            onChange={(newValue) => {
                                setPrice(newValue)
                                setValue('DealSearch.project_price_id', newValue.value)
                            }}
                            options={prices}/>
                    </div>
                    {userCan(user.roles, ['admin', 'backoffice', 'supervisor', 'manager']) && (
                        <div className="mb-3 col-md-3">
                            <label htmlFor="projectOrganizationId" className="form-label">Подразделение</label>
                            <Select
                                defaultValue={organization}
                                value={organization || null}
                                placeholder={'Выберите подразделение'}
                                onChange={(newValue) => {
                                    setOrganization(newValue)
                                    setValue('DealSearch.project_organization_id', newValue.value)
                                }}
                                options={organizations}/>
                        </div>
                    )}
                    {userCan(user.roles, ['admin', 'backoffice', 'supervisor']) && (
                        <div className="mb-3 col-md-3">
                            <label htmlFor="userId" className="form-label">Пользователь</label>
                            <Select
                                placeholder={'Выберите пользователя'}/>
                        </div>
                    )}
                </div>
                <div className="row">
                    <div className="mb-3 col-md-3">
                        <label htmlFor="contract_code" className="form-label">Номер сертификата</label>
                        <input id="contract_code" {...register("DealSearch.contract_code", {maxLength: 50})}
                               className={"form-control " + (errors?.DealSearch?.contract_code ? "is-invalid" : '')}/>
                    </div>
                    <div className="mb-3 col-md-3">
                        <label htmlFor="dateIssuedAt" className="form-label">Дата оформления</label>
                        <DatePicker
                            selectsRange={true}
                            dateFormat={'dd.MM.yyyy'}
                            className={"form-control " + (errors?.DealSearch?.dateIssuedAt ? "is-invalid" : '')}
                            startDate={issuedStartDate}
                            endDate={issuedEndDate}
                            onChange={(update) => {
                                setIssuedDateRange(update);
                                console.log(update)
                                setValue('DealSearch.dateIssuedAt', update[0] && update[1] ? formatDateRange(update) : null)
                            }}
                            isClearable={true}
                        />
                    </div>

                    <div className="mb-3 col-md-3">
                        <label htmlFor="statusName" className="form-label">Статус</label>
                        <Select
                            defaultValue={status}
                            value={status || null}
                            placeholder={'Выбрать'}
                            onChange={(newValue) => {
                                setStatus(newValue)
                                setValue('DealSearch.statusName', newValue.value)
                            }}
                            options={statuses}/>
                    </div>
                    <div className="mb-3 col-md-3">
                        <label htmlFor="paymentStatusName" className="form-label">Статус оплаты</label>
                        <Select
                            defaultValue={paymentStatus}
                            value={paymentStatus || null}
                            placeholder={'Выбрать'}
                            onChange={(newValue) => {
                                setPaymentStatus(newValue)
                                console.log(newValue.value)
                                setValue('DealSearch.paymentStatus', newValue.value)
                            }}
                            options={paymentStatuses}/>
                    </div>
                </div>
                <div className="row">
                    <div className="mb-3 col-md-3">
                        <label htmlFor="deal_data_client_fio" className="form-label">ФИО Клиента</label>
                        <input
                            id="deal_data_client_fio" {...register("DealSearch.deal_data_client_fio", {maxLength: 50})}
                            className={"form-control " + (errors?.DealSearch?.deal_data_client_fio ? "is-invalid" : '')}/>
                    </div>
                    <div className="mb-3 col-md-3">
                        <label htmlFor="deal_data_client_phone" className="form-label">Телефон</label>
                        <input
                            id="deal_data_client_phone" {...register("DealSearch.deal_data_client_phone", {maxLength: 50})}
                            className={"form-control " + (errors?.DealSearch?.deal_data_client_phone ? "is-invalid" : '')}
                        />
                    </div>
                    <div className="mb-3 col-md-3">
                        <label htmlFor="deal_data_client_email" className="form-label">Email</label>
                        <input
                            id="deal_data_client_email" {...register("DealSearch.deal_data_client_email", {maxLength: 50})}
                            className={"form-control " + (errors?.DealSearch?.deal_data_client_email ? "is-invalid" : '')}
                        />
                    </div>
                    <div className="mb-3 col-md-3">
                        <label htmlFor="productId" className="form-label">Тестовая</label>
                        <Select
                            defaultValue={isTest}
                            value={isTest || null}
                            placeholder={'Выбрать'}
                            onChange={(newValue) => {
                                setIsTest(newValue)
                                setValue('DealSearch.is_test', newValue.value)
                            }}
                            options={testsOptions}/>
                    </div>
                </div>

                {!isLoading && <button type="submit" className="btn btn-primary">
                    <svg width="20" height="20" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z"
                            stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    Искать</button>}
                {isLoading && <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>}
                <button type="button" onClick={_resetForm} className="btn btn-default">Очистить</button>
            </form>
        </div>
    );
}

export default SearchComponent;
