import React, {memo} from "react";

const Timeline = ({deal, projectStatuses = []}) => {

    const currentDt = new Date().toLocaleString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    }).replace(',', '');

    return (
        <div className="mb-3">
            <div id="tracking">
                <div className="tracking-list">
                    {!deal ? (
                        <div className="tracking-item">
                            <div className="tracking-icon status-intransit tracking-active">
                                <h5 style={{color: '#0b2e13', marginTop: '6px'}}>1</h5>
                            </div>
                            <div className="tracking-content">Создание</div>
                            <div className="tracking-date"><span>Дата {currentDt}</span>
                                <span>Заполните поля для создания сделки</span></div>
                        </div>
                    ) : (
                        <>
                            {projectStatuses.map((status, index) => {
                                    return (
                                        <div key={status.id}
                                            className={`tracking-item`}>
                                            <div className={`tracking-icon status-intransit ${status.id === deal.project_workflow_status_id ? 'tracking-active' : ''}`}>
                                                <h5 style={{color: '#0b2e13', marginTop: '6px'}}>{index + 1}</h5>
                                            </div>
                                            <div className="tracking-content">{status.name}</div>
                                            {!index && <div className="tracking-date"><span>{deal.created_at}</span></div>}
                                        </div>
                                    );
                                }
                            )}
                        </>
                    )}

                </div>
            </div>
        </div>
    );
}

export default memo(Timeline);
