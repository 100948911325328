import {DEAL_REDUCER} from "../../utils/consts";

const initialState = {
  isNeedRefresh: false,
};

const DealReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEAL_REDUCER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default DealReducer;
